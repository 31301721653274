import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch } from "react-redux";
import { DeleteUser, getuserprofile, maangeuseraction } from "../Redux/Actions/AdminActions";
import placeholder from "../Assets/Images/Road-assistance-placehodler.png";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function UserOverview() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active

  const navigate = useNavigate();

  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [Userdetails, setuserDetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(true);
  const [type, setType] = useState("");

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ id: id }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setuserDetails(response?.payload?.data?.data?.user);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, [id,dispatch]);

  const handleDeleteAccount = () => {
    dispatch(DeleteUser({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/user");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuspendAccount = () => {
    dispatch(maangeuseraction({ userId: id, type: type }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/user");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={
                      Userdetails?.profileImage
                        ? `${url}${Userdetails?.profileImage}`
                        : placeholder
                    }
                  />
                  <h2>{Userdetails?.name || "N/A"}</h2>
                </div>

                <div className="profile-data">
                  <div className="total-view">
                    <div className="total-image-icon">
                      <img
                        src={
                          require("../Assets/Images/total-booking-icon.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="text-veiw">
                      <p>04</p>
                      <span>Total Bookings</span>
                    </div>
                  </div>
                  <div className="total-view">
                    <div className="total-image-icon">
                      <img
                        src={
                          require("../Assets/Images/cancelled-icon.svg").default
                        }
                      />
                    </div>
                    <div className="text-veiw">
                      <p>02</p>
                      <span>Cancelled</span>
                    </div>
                  </div>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username: <span>{Userdetails?.name || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email: <span>{Userdetails?.email || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                            {Userdetails?.countryCode &&
                            Userdetails?.phoneNumber
                              ? `${Userdetails?.countryCode} ${Userdetails?.phoneNumber}`
                              : "N/A"}{" "}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="toggle-box-top">
                  <div className="over-view-btn">
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <circle
                            cx="6.75"
                            cy="5.25"
                            r="3"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Overview
                    </p>
                  </div>
                </div>

                <div className="outer-shadow-box">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Recent Activity</h2>
                      </div>

                      <div class="table-responsive new-over-view">
                        <Table size="sm" className="table-cmn">
                          <thead>
                            <tr>
                              <th>S.no.</th>
                              <th>Pickup location</th>
                              <th>Dropoff location</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>Mohali</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Shivalik City</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>22-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="pending">
                                  <p>Cancelled</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>Chandigarh</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Home Land</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>25-Sep-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="completed-btn">
                                  <p>Completed</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>Quark City</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Nature City</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>20-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="pending">
                                  <p>Cancelled</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>Delhi</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>ATS Floor</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>15-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="completed-btn">
                                  <p>Completed</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="pagination-section">
                          <div className="showing-user">
                            <p>Showing 1 to 5 of 11 entries</p>
                          </div>
                          <div className="pagination-block">
                            <Pagination>
                              <Pagination.First />
                              <Pagination.Prev />
                              
                              <Pagination.Item active>{1}</Pagination.Item>
                          
                              <Pagination.Next />
                              <Pagination.Last />
                            </Pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="account-setting">
            <div className="delete-account">
              <div className="delete-heading-text">
                <h1>Delete Account</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to delete this account?</p>
                <span>
                  Once you delete this account, there is no going back. Please
                  be certain.
                </span>
              </div>
              <div className="delete-check-box">
                {/* <div className="remember-check check-box-over">
                  <input type="checkbox" class="red" id="filled-in-box" />
                  <Form.Label>I confirm account Delete</Form.Label>
                </div> */}
              </div>
              <div className="btn-delete-account">
                <button onClick={() => setshowd(true)}>Delete account</button>
              </div>
            </div>

            <div className="delete-account">
              <div className="delete-heading-text">
                <h1>Suspend Account</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to suspend this account?</p>
                <span>
                  You will not be able to receive messages, notifications for up
                  to 24hours.
                </span>
              </div>
              <div className="delete-check-box">
                {/* <div className="remember-check check-box-over">
                  <input type="checkbox" class="red" id="filled-in-box" />
                  <Form.Label>I confirm account Delete</Form.Label>
                </div> */}
              </div>
              <div className="btn-delete-account">
                <button
                  onClick={() => {
                    setshow(true);
                    setType("0");
                  }}
                >
                  Suspend account
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to delete this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn done-btn" onClick={handleDeleteAccount}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Suspend this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn done-btn" onClick={handleSuspendAccount}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
