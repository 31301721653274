import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../Services/AdminApi";


//  Admin Login

export const login = createAsyncThunk(
    "login",
    async(details)=> {
        const  data   = await AdminApi.post('/login', details);
        return data
    }
)

// ========= Forgot Password =========

export const forgetPass = createAsyncThunk(
    "forgetPass",
    async(details) => {
        const data = await AdminApi.post(`/forgetPassword`,details);
       return data;
    } 
  );

// ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
    "ResetPass",
    async(details) => {
        const data = await AdminApi.post(`/resetPassword`,details);
       return data;
    } 
  );

    // ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
    "chnagePassword",
    async (details) => {
      const data = await AdminApi.post(`/changepassword`, details);
      console.log(data);
      return data;
    }
  );


