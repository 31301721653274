import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import UserOverview from "./Pages/UserOverview";
import ServiceVerificationDetails from "./Pages/ServiceVerificationDetails";
import ServiceProviders from "./Pages/ServiceProviders";
import { ProfileProvider } from "./Context/ProfileContext";
import ProtectedRoutes from "./Components/ProtectedRoute";
import Report from "./Pages/Report";
import ReportView from "./Pages/ReportView";
import Support from "./Pages/Support";
import ChatSupport from "./Pages/ChatSupport";
import Notification from "./Pages/Notification";
import CreateNotification from "./Pages/CreateNotificaion";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ProfileProvider>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
              path="/reset-password/:token"
              element={<ResetPassword />}
            ></Route>
            <Route
              path="/otp-varification"
              element={<OtpVarification />}
            ></Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/error" element={<Error />}></Route>
              <Route
                path="/under-maintenance"
                element={<UnderMaintenance />}
              ></Route>
              <Route path="/user" element={<User />}></Route>
              <Route path="/my-profile" element={<MyProfile />}></Route>
              <Route
                path="/user-overview/:id"
                element={<UserOverview />}
              ></Route>
              <Route
                path="/service-verification-details/:id"
                element={<ServiceVerificationDetails />}
              ></Route>
              <Route
                path="/service-providers"
                element={<ServiceProviders />}
              ></Route>
              <Route
                path="/user-verification"
                element={<UserVerification />}
              ></Route>
              <Route path="/report" element={<Report/>} />
              <Route path="/report-view" element={<ReportView/>} />
              <Route path="/support" element={<Support/>} />
              <Route path="/chat-support" element={<ChatSupport/>} />
              <Route path="/notification" element={<Notification/>} />
              <Route path="/create-notification" element={<CreateNotification/>} />

            </Route>
          </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
