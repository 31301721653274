import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useState } from "react";
import OtpInput from "react-otp-input";

export default function OtpVarification() {
  const [otp, setOtp] = useState("");

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/side-bar-logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="new-otp-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Two Step Verification 💬</h2>
                  <p className="mb-0">
                    We sent a verification code to your mobile. Enter the code
                    from the mobile in the field below.
                    <br />
                    <span>******1234</span>
                  </p>
                  <Form>
                    <div className="form-set set-otp">
                      <OtpInput
                        value={otp}
                        // inputType="number"
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <Link
                      to="/user"
                      variant="primary"
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Verify my account
                    </Link>
                    <div className="resend-mail">
                      <p>
                        Didn't get the mail? <Link to="/"> Resend </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
