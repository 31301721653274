import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form"; 

const SearchBar = ({ setSearchValue }) => {
  const [searchText, setSearchText] = useState("");
  const timeout = 600; 

  useEffect(() => {
    const handler = setTimeout(() => {
      console.log("Searching for:", searchText);
      setSearchValue(searchText);
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]); 

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div >
      <Form.Control
        type="search"
        placeholder="Search"
        className="form-control w-100"
        value={searchText}
        onChange={handleSearchChange}
      />

    </div>
  );
};

export default SearchBar;
