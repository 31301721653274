import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch } from "react-redux";
import { getuserprofile } from "../Redux/Actions/AdminActions";
import placeholder from "../Assets/Images/Road-assistance-placehodler.png";
import { BsFileEarmarkPdf } from "react-icons/bs";
import ImagePreviewModal from "../Common/ImagePreviewModal";
export default function ServiceVerificationDetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [Userdetails, setuserDetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(true);
  const [type, setType] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ id: id }))
      .then((response) => {
        console.log(response);
        if (response?.payload?.data?.status === 200) {
          setuserDetails(response?.payload?.data?.data?.user);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, [id, dispatch]);

    

  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };

  return (
    <Layout>
      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={
                      Userdetails?.profileImage
                        ? `${url}${Userdetails?.profileImage}`
                        : placeholder
                    }
                  />
                  <h2>{Userdetails?.name || "N/A"}</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username: <span>{Userdetails?.name || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email: <span>{Userdetails?.email || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                            {Userdetails?.countryCode &&
                            Userdetails?.phoneNumber
                              ? `${Userdetails?.countryCode} ${Userdetails?.phoneNumber}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box ">
                  {Userdetails?.servicesOffered?.map((service) => {
                    console.log(service);

                    return (
                      <div className="grid-over-view">
                        <div className="inner-filter-field">
                          <div className="image-heading-text">
                            <h2>{service?.serviceId?.service_name}</h2>
                          </div>
                          <div className="booking-info">
                            <div className="booking-grid">
                              {service?.serviceId?.service_name ===
                                "Car towing" && (
                                <div className="booking-label">
                                  
                                  <label>Vehicle registration number</label>
                                  <p>
                                    {Userdetails?.vehicleRegistrationNumber ||
                                      "N/A"}
                                  </p>
                                </div>
                              )}

                              {service?.imagesArray.map((docs, index) => {
                                return (
                                  <div className="booking-label" key={index}>
                                    <label>Document {index + 1}</label>
                                    <p>
                                      {docs?.endsWith(".jpg") ||
                                      docs?.endsWith(".jpeg") ||
                                      docs?.endsWith(".png") ? (
                                        <img
                                        onClick={() => handlePrivewImagesOpen(docs)}
                                          src={`${url}${docs}`}
                                          alt={`Document ${index + 1}`}
                                        />
                                      ) : docs?.endsWith(".pdf") ? (
                                        <div>
                                          <img
                                            src={`${url}${docs}`}
                                            alt="PDF Icon"
                                          />{" "}
                                          <BsFileEarmarkPdf
                                            color="red"
                                            size={50}
                                          />
                                        </div>
                                      ) : (
                                        <span>Document not found</span>
                                      )}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Car towing</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Vehicle registration number</label>
                            <p>{Userdetails?.vehicleRegistrationNumber  || "N/A"}</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 1</label>
                            <p>pdf.1</p>
                          </div>

                          <div className="booking-label">
                            <label>Document 2 </label>
                            <p>pdf.1</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 3</label>
                            <p>pdf.1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="outer-shadow-box ">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Battery jump</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Vehicle registration number</label>
                            <p>{Userdetails?.vehicleRegistrationNumber || "N/A"}</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 1</label>
                            <p>pdf.1</p>
                          </div>

                          <div className="booking-label">
                            <label>Document 2 </label>
                            <p>pdf.1</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 3</label>
                            <p>pdf.1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
      <ImagePreviewModal
          handlePrivewImagesClose={handlePrivewImagesClose}
          imageModal={imageModal}
          selectedImage={selectedImage}
        />
    </Layout>
  );
}
