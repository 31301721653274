import React, { useState } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "../TopHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  const shouldHideBackButton =
    currentPath === "/user" ||
    currentPath === "/user-verification" ||
    currentPath === "/report" ||
    currentPath === "/support" ||
    currentPath === "/notification";

  return (
    <div>
      <div className="main-wrap">
        <div className={show ? "left-side hide" : "left-side "}>
          <Sidebar
            isActive={isActive}
            setIsActive={setIsActive}
            show={show}
            setShow={setShow}
          />
        </div>
        <div className={show ? "right-side-cmn hide" : "right-side-cmn "}>
          <div>
            <TopHeader />
            <div className="mx-3">
              {!shouldHideBackButton && (
                <IoArrowBack onClick={() => navigate(-1)} size={30} />
              )}
            </div>

            {children}
          </div>
          {/* <div className="made-with">
            <div className="pixinvent">
              <p> © 2024, Copyright 2024</p>
            </div>
            <div className="bottom-links">
              <Link to="#">License</Link>
              <Link to="#">More Themes</Link>
              <Link to="#">Documentation</Link>
              <Link to="#">Support</Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
