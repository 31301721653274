import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ResetPass } from "../Redux/Actions/AdminAuth";


const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(
      6,
      <span className="error-message">
        Password must be at least 6 characters
      </span>
    )
    .required(<span className="error-message">Required</span>),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      <span className="error-message">Passwords must match</span>
    )
    .required(<span className="error-message">Required</span>),
});

export default function ResetPassword() {

  const dispatch = useDispatch();
  const location = useLocation();
  
  const pathname = location.pathname;
  console.log(pathname, "pathname");

  
  const token = pathname.split('/reset-password/')[1];
  console.log(token, "token");
  

  const initialvalues = {
    newPassword: "",
    confirmPassword: "",
  };


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/side-bar-logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="reset-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Reset Passwoard 🔒</h2>
                  <p className="mb-0">
                    Your new password must be different from previously used
                    passwords
                  </p>

                  <Formik
                    initialValues={initialvalues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log(values, "values");

                      const payload = {
                        password: values?.newPassword,
                        token: token,
                      };
                      dispatch(ResetPass(payload))
                        .then((res) => {
                          console.log(res,"response after reset");
                          if (res?.payload?.data?.status === 200) {
                            toast.success(res?.payload?.data?.message);
                            setTimeout(() => {
                              toast.dismiss();
                              window.location.href = "/";
                            }, 1500);
                          } else {
                            toast.error(res?.payload?.data?.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error, "error occurred");
                        });
                    }}
                  >
                    {({ values, handleSubmit, handleChange }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-set">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          name="newPassword"
                          value={values?.newPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="newPassword" component="div"/>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={values?.confirmPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                    <button
                      // to=""
                      variant="primary"
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Set New Password
                    </button>
                    <div className="back-link-ad">
                      <Link to="/forgot-password">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="13"
                          viewBox="0 0 7 13"
                          fill="none"
                        >
                          <path
                            d="M6 1.5L1 6.5L6 11.5"
                            stroke="#7367F0"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Back to login
                      </Link>
                    </div>
                  </Form>
                   )}
                   </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
