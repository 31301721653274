import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function ServiceProviders() {
  return (
    <Layout>
      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={require("../Assets/Images/my-profile.svg").default}
                  />
                  <h2>Seth Hallam</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username: <span>@shallamb</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email: <span>shallamb@gmail.com</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact: <span>+1 (234) 464-0600</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box mt-0">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Service type</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <p>Car towing</p>
                          </div>
                          <div className="booking-label">
                            <p>Battery jump</p>
                          </div>

                          <div className="booking-label">
                            <p>Minor repairs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="outer-shadow-box ">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Car towing</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Vehicle registration number</label>
                            <p>XYZ123</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 1</label>
                            <p>pdf.1</p>
                          </div>

                          <div className="booking-label">
                            <label>Document 2 </label>
                            <p>pdf.1</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 3</label>
                            <p>pdf.1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="outer-shadow-box ">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Battery jump</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Vehicle registration number</label>
                            <p>XYZ123</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 1</label>
                            <p>pdf.1</p>
                          </div>

                          <div className="booking-label">
                            <label>Document 2 </label>
                            <p>pdf.1</p>
                          </div>
                          <div className="booking-label">
                            <label>Document 3</label>
                            <p>pdf.1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="outer-shadow-box">
          <div className="grid-over-view">
            <div className="inner-filter-field">
              <div className="image-heading-text">
                <h2>Recent Activity</h2>
              </div>

              <div class="table-responsive new-over-view">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Pickup location</th>
                      <th>Dropoff location</th>
                      <th>Estimated price</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="">
                          <div className="user-id">
                            <p>Alpha Homes</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>Alpha Homes</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>$50</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>22-Jan-2024 </p>
                        </div>
                      </td>
                      <td>
                        <div className="pending">
                          <p>Cancelled</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="">
                          <div className="user-id">
                            <p>Alpha Homes</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>Alpha Homes</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>$50</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>22-Jan-2024 </p>
                        </div>
                      </td>
                      <td>
                        <div className="pending">
                          <p>Cancelled</p>
                        </div>
                      </td>
                    </tr><tr>
                      <td>01</td>
                      <td>
                        <div className="">
                          <div className="user-id">
                            <p>Alpha Homes</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>Alpha Homes</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>$50</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>22-Jan-2024 </p>
                        </div>
                      </td>
                      <td>
                        <div className="pending">
                          <p>Cancelled</p>
                        </div>
                      </td>
                    </tr><tr>
                      <td>01</td>
                      <td>
                        <div className="">
                          <div className="user-id">
                            <p>Alpha Homes</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>Alpha Homes</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>$50</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>22-Jan-2024 </p>
                        </div>
                      </td>
                      <td>
                        <div className="pending">
                          <p>Cancelled</p>
                        </div>
                      </td>
                    </tr><tr>
                      <td>01</td>
                      <td>
                        <div className="">
                          <div className="user-id">
                            <p>Alpha Homes</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>Alpha Homes</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>$50</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-role">
                          <p>22-Jan-2024 </p>
                        </div>
                      </td>
                      <td>
                        <div className="pending">
                          <p>Cancelled</p>
                        </div>
                      </td>
                    </tr>
                  
                  </tbody>
                </Table>
                <div className="pagination-section">
                  <div className="showing-user">
                    <p>Showing 1 to 5 of 11 entries</p>
                  </div>
                  <div className="pagination-block">
                    <Pagination>
                      <Pagination.First />
                      <Pagination.Prev />
                      <Pagination.Item>{1}</Pagination.Item>
                      <Pagination.Item>{2}</Pagination.Item>
                      <Pagination.Item active>{3}</Pagination.Item>
                      <Pagination.Item>{4}</Pagination.Item>
                      <Pagination.Item>{5}</Pagination.Item>
                      <Pagination.Next />
                      <Pagination.Last />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="account-setting">
          <div className="delete-account">
            <div className="delete-heading-text">
              <h1>Delete Account</h1>
            </div>
            <div className="delete-disp">
              <p>Are you sure you want to delete this account?</p>
              <span>
                Once you delete this account, there is no going back. Please be
                certain.
              </span>
            </div>
            <div className="delete-check-box">
              <div className="remember-check check-box-over">
                <input type="checkbox" class="red" id="filled-in-box" />
                <Form.Label>I confirm account Delete</Form.Label>
              </div>
            </div>
            <div className="btn-delete-account">
              <button>Delete account</button>
            </div>
          </div>

          <div className="delete-account">
            <div className="delete-heading-text">
              <h1>Suspend Account</h1>
            </div>
            <div className="delete-disp">
              <p>Are you sure you want to Suspend this account?</p>
              <span>
                You will not be able to receive messages, notifications for up
                to 24hours.
              </span>
            </div>
            <div className="delete-check-box">
              <div className="remember-check check-box-over">
                <input type="checkbox" class="red" id="filled-in-box" />
                <Form.Label>I confirm account Delete</Form.Label>
              </div>
            </div>
            <div className="btn-delete-account">
              <button>Suspend account</button>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
