import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";

export default function CreateNotification() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Create Notification</h2>
            </div>
            <div className="delete-post-form">
              <Row className="post-view create-notification">
                <Col>
                  <div className="profile-disp post-view-margin-top">
                    <div className="form-set input-profile ">
                      <Form.Group
                        className="mb-3 input-field-width "
                        controlId="postViewFormName"
                      >
                        <Form.Label>Select User</Form.Label>
                        <Form.Select type="text" placeholder="Search" className="select-option">
                          <option >Search</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width "
                        controlId="postViewFormTitle"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Type here" />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width post-view-input-width "
                        controlId="postViewFormDescription"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Type here"
                          className="description-textarea"
                        />
                      </Form.Group>
                    </div>
                    <div className="create-btn">
                      <Button type="suvbmit">Create</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
