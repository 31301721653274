import axios from "axios"

export const AdminApi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    headers: {
        Authorization: sessionStorage.getItem("token"),
    },
});

AdminApi.interceptors.response.use(
    (response)=> {
        return response;
    },
    (error) => {
        return error.response;
    }
)