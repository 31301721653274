import { Link } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import { Container, Form, Pagination, Table } from "react-bootstrap";

export default function Report() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Report</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>10</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn table-report">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>REASON</th>
                    <th>DESCRIPTION</th>
                    <th>SENT BY</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>01</p>
                    </td>
                    <td>
                      <Link to="/report-view"><p>warnings</p></Link>
                    </td>
                    <td>
                      <p>Hey you! New products are waiting.</p>
                    </td>
                    <td>
                      <p>James Mathew</p>
                    </td>

                    <td>
                      <div className="delete">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="made-with">
          <div className="pixinvent">
            <p> © 2024, Copyright 2024</p>
          </div>
          <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div>
        </div> */}
      </Container>
    </Layout>
  );
}
