import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../Services/AdminApi";

// ===== Get Admin Profile ==========

export const getAdminprofile = createAsyncThunk(
  "getAdminprofile",
  async (details) => {
    const { data } = await AdminApi.get(`/getAdminById`, details);
    return data;
  }
);

// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminApi.put(`/editAdminProfile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    if (details.role) {
      url += `&role=${details.role}`;
    }
    const data = await AdminApi.get(url);
    return data;
  }
);

// =======  Dowanload Users List ============

export const DowanloadUserxlsv = createAsyncThunk(
  "DowanloadUserxlsv",
  async () => {
    const data = await AdminApi.get(`/userManagement`);
    console.log(data);
    return data;
  }
);

// =======  Dowanload service provider List ============

export const Dowanloadserviceprovider = createAsyncThunk(
  "Dowanloadserviceprovider",
  async (details) => {
    let url = `/userManagement?page=${details?.page}`;
    if (details.role) {
      url += `&role=${details.role}`;
    }
    const data = await AdminApi.get(url);
    console.log(data);
    return data;
  }
);

// ======== Delete User account api =============

export const DeleteUser = createAsyncThunk("DeleteUser", async (details) => {
  const data = await AdminApi.delete(`/deleteUser/${details?.id}`);
  return data;
});


// ======== magage User account api =============

export const maangeuseraction = createAsyncThunk(
  "maangeuseraction",
  async (details) => {
    const data = await AdminApi.post(`/banUnbanUser`, details);
    return data;
  }
);


// ======== verify unverify serviceprovider api =============

export const verifyServiceprovider = createAsyncThunk(
  "verifyServiceprovider",
  async (details) => {
    const data = await AdminApi.post(`/verifyUnverifyUser`, details);
    return data;
  }
);



// ========= get user profile by id ==========

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminApi.get(`/getUserById/${details?.id}`);
    console.log(data);
    return data;
  }
);


//  get dashboard api 

export const getdashboard = createAsyncThunk(
  "getdashboard",
  async (details) => {
    const data = await AdminApi.get(`/dashboard`);
    console.log(data);
    return data;
  }
);
