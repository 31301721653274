import { configureStore } from '@reduxjs/toolkit'
import { usermanageSlice } from './Reducer/UsermanagementSlice';
import { dashboardSlice } from './Reducer/DashboardSlice';




export const Store = configureStore({
    reducer: {
        usermgt:usermanageSlice.reducer,
        dash:dashboardSlice.reducer
        
    },
});