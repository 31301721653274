import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";

export default function ChatSupport() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Jordan Stevenson </h2>
            </div>
            <div className="chat-support-main-content">
              <div className="chat-content">
                <div className="right-chat">
                  <div className="right-chat-content">
                    <p>Absolutely!</p>
                    <p> This admin template is built with MUI!</p>
                    <div className="right-chat-time">
                      <div>
                        <img
                          src={require("../Assets/Images/checks.svg").default}
                          alt="Mic Icon"
                        />
                      </div>
                      <span> 1:16 PM</span>
                    </div>
                  </div>
                  <div className="left-chat-user-img">
                    <img
                      src={require("../Assets/Images/user.svg").default}
                      alt="Mic Icon"
                    />
                  </div>
                </div>
                <div className="left-chat">
                  <div className="left-chat-user-img">
                    <img
                      src={require("../Assets/Images/avatar.png")}
                      alt="Mic Icon"
                    />
                  </div>
                  <div className="left-chat-content">
                    <p>Looks clean and fresh UI. 😍</p>
                    <p> It's perfect for my next project.</p>
                    <p> How can I purchase it?</p>
                    <span> 1:17 PM</span>
                  </div>
                </div>
                
              </div>
              <div className="input-send">
                <InputGroup className="">
                  <Form.Control
                    placeholder="Type your messange here..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div className="right-send-side">
                    <div className="mic-icon">
                      <img
                        src={require("../Assets/Images/mic.svg").default}
                        alt="Mic Icon"
                      />
                    </div>
                    <div className="mic-icon">
                      <img
                        src={require("../Assets/Images/attachment.svg").default}
                        alt="Attachment Icon"
                      />
                    </div>
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      className="send-btn"
                    >
                      Send
                      <div className="send-icon">
                        <img
                          src={require("../Assets/Images/send.svg").default}
                          alt="Send Icon"
                        />
                      </div>
                    </Button>
                  </div>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
