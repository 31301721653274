import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";

export default function ReportView() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filtr-field">
            <div>
              <h2 className="report-heading"> Report View</h2>
            </div>
            <div className="delete-post-form">
              <Row className="post-view">
                <Col >
                  <div className="profile-disp post-view-margin-top">
                    <div className="form-set input-profile post-view-form">
                      <Form.Group
                        className="mb-3 input-field-width "
                        controlId="postViewFormName"
                      >
                        <Form.Label>Sent By</Form.Label>
                        <Form.Control type="text" placeholder="User Name" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="postViewFormReportedUser"
                      >
                        <Form.Label>Reason</Form.Label>
                        <Form.Control type="text" placeholder=" Lorem Gypsum" />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width post-view-input-width "
                        controlId="postViewFormDescription"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Type here"
                          className="description-textarea"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
                
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
